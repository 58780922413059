import 'core-js/modules/es.object.assign'

import React from 'react';
import { render, hydrate } from 'react-dom';
import ApolloClient from 'apollo-boost';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';
import { Event, EventHint } from '@sentry/types/dist/event';

import App from './App';
import './styles/index.css';
import * as serviceWorker from './serviceWorker';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
});

Sentry.init({
  dsn: 'https://1e946a17eca64292bd0295ea747f2591@sentry.io/1889185',
  beforeSend(event: Event, hint?: EventHint | undefined): PromiseLike<Event | null> | Event | null {
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        subtitle: 'Our team has been notified. If you are using old browser, please update to the latest version.'
      });
    }

    return event;
  }
});

const rootElement = document.getElementById('root');


if (rootElement!.hasChildNodes()) {
  hydrate((
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  ), rootElement);
} else {
  render((
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  ), rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
