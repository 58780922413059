import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router";
// @ts-ignore
import loadable from "@loadable/component";

const IndexPage = loadable(() => import("./pages/index"));
const AboutPage = loadable(() => import("./pages/about"));
const TeamPage = loadable(() => import("./pages/team"));
const ProgramsPage = loadable(() => import("./pages/programs"));
const CSGActivitiesPage = loadable(() => import("./pages/csg-activities"));
const ResourcesPage = loadable(() => import("./pages/resources"));
const CSGFellowshipPage = loadable(() => import("./pages/csgfellowship"));
const FellowshipsPage = loadable(() => import("./pages/fellowships"));
const FAQPage = loadable(() => import("./pages/faq"));
const JoinUsPage = loadable(() => import("./pages/join-us"));
const RCRTrainingRequestPage = loadable(
  () => import("./pages/rcr-training-request")
);
const NewsAndEventsPage = loadable(() => import("./pages/news-and-events"));
const NYC18Page = loadable(() => import("./pages/nyc18-2"));
const PostPage = loadable(() => import("./pages/post"));
const BlogletsPage = loadable(() => import("./pages/bloglets"));
const AdvertisingAndBusinessServicesPage = loadable(
  () => import("./pages/advertising-and-business-services")
);
const TelecommunicationServicesPage = loadable(
  () => import("./pages/telecommunication-services")
);
const EducationServicesPage = loadable(
  () => import("./pages/education-services")
);
const ScienceAndTechnologyServicesPage = loadable(
  () => import("./pages/science-and-technology-services")
);

const Router: FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/" exact children={<IndexPage />} />
      <Route path="/about" exact children={<AboutPage />} />
      <Route path="/team" exact children={<TeamPage />} />
      <Route path="/programs" exact children={<ProgramsPage />} />
      <Route path="/resources" exact children={<CSGActivitiesPage />} />
      <Route path="/careerresources" exact children={<ResourcesPage />} />
      <Route path="/csgfellowship" exact children={<CSGFellowshipPage />} />
      <Route path="/fellowships" exact children={<FellowshipsPage />} />
      <Route path="/faq" exact children={<FAQPage />} />
      <Route path="/join-us" exact children={<JoinUsPage />} />
      {/*<Route path="/mandatory-rcr-training-request" exact children={<RCRTrainingRequestPage />} />*/}
      <Route path="/news-and-events" exact children={<NewsAndEventsPage />} />
      <Route path="/nyc18-2" exact children={<NYC18Page />} />
      <Route path="/post/:slug" exact children={<PostPage />} />
      <Route path="/bloglets" exact children={<BlogletsPage />} />
      <Route path="/event/:slug" exact children={<PostPage />} />
      <Route
        path="/advertising-and-business-services"
        exact
        children={<AdvertisingAndBusinessServicesPage />}
      />
      <Route
        path="/telecommunication-services"
        exact
        children={<TelecommunicationServicesPage />}
      />
      <Route
        path="/education-services"
        exact
        children={<EducationServicesPage />}
      />
      <Route
        path="/science-and-technology-services"
        exact
        children={<ScienceAndTechnologyServicesPage />}
      />
    </Switch>
  );
};

export default Router;
